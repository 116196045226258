import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div id="header">

        <div className="top">

          <div id="logo">
            <span className="image avatar48"><img src="images/avatar.jpg" alt="" /></span>
            <h1 id="title">PROTECO</h1>
            <p>Producción Técnica de Comestibles</p>
          </div>

          <nav id="nav">
            <ul>
              <li><a href="#top" id="top-link"><span className="icon solid fa-home">Inicio</span></a></li>
              <li><a href="#about" id="about-link"><span className="icon solid fa-user">Acerca de</span></a></li>
              <li><a href="#portfolio" id="portfolio-link"><span className="icon solid fa-th">Productos</span></a></li>
              <li className="invisible"><a href="#contact" id="contact-link"><span className="icon solid fa-envelope">Contact</span></a></li>
            </ul>
          </nav>

        </div>

        <div className="bottom">
          <ul className="icons">
            <li className="invisible"><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
            <li className="invisible"><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
            <li className="invisible"><a href="#" className="icon brands fa-github"><span className="label">Github</span></a></li>
            <li className="invisible"><a href="#" className="icon brands fa-dribbble"><span className="label">Dribbble</span></a></li>
            <li><a href="mailto:contacto@proteco.com.mx" className="icon solid fa-envelope"><span className="label">Email</span></a></li>
          </ul>
        </div>

      </div>

      <div id="main">

        <section id="top" className="one dark cover">
          <div className="container">

            <header>
              <span className="image"><img src="images/logo.jpeg" alt="PROTECO"/></span>
              <p>Producción Técnica de Comestibles</p>
            </header>

            <footer className="invisible">
              <a href="#portfolio" className="button scrolly">Magna Aliquam</a>
            </footer>

          </div>
        </section>

        <section id="about" className="three">
          <div className="container">

            <header>
              <h2>Acerca de</h2>
            </header>

            <a href="#" className="image featured"><img src="images/pic08.jpg" alt="" /></a>

            <p>Tincidunt eu elit diam magnis pretium accumsan etiam id urna. Ridiculus
              ultricies curae quis et rhoncus velit. Lobortis elementum aliquet nec vitae
              laoreet eget cubilia quam non etiam odio tincidunt montes. Elementum sem
              parturient nulla quam placerat viverra mauris non cum elit tempus ullamcorper
              dolor. Libero rutrum ut lacinia donec curae mus vel quisque sociis nec
              ornare iaculis.</p>

          </div>
        </section>

        <section id="portfolio" className="two">
          <div className="container">

            <header>
              <h2>Productos</h2>
            </header>

            <p>Vitae natoque dictum etiam semper magnis enim feugiat convallis convallis
              egestas rhoncus ridiculus in quis risus amet curabitur tempor orci penatibus.
              Tellus erat mauris ipsum fermentum etiam vivamus eget. Nunc nibh morbi quis
              fusce hendrerit lacus ridiculus.</p>

            <div className="row">
              <div className="col-4 col-12-mobile">
                <article className="item">
                  <a href="#" className="image fit"><img src="images/pic02.jpg" alt="" /></a>
                  <header>
                    <h3>Chile</h3>
                  </header>
                </article>
              </div>
              <div className="col-4 col-12-mobile">
                <article className="item">
                  <a href="#" className="image fit"><img src="images/huevitoLindo.png" alt="" /></a>
                  <header>
                    <h3>Huevito Lindo</h3>
                  </header>
                </article>
              </div>
              <div className="col-4 col-12-mobile">
                <article className="item">
                  <a href="#" className="image fit"><img src="images/pic06.jpg" alt="" /></a>
                  <header>
                    <h3>Chile</h3>
                  </header>
                </article>
              </div>
            </div>

          </div>
        </section>

        <section id="contact" className="four">
          <div className="container">

            <header>
              <h2>Contacto</h2>
            </header>

            <p>Si gustas comprar alguno de nuestros productos</p>

            <form method="post" action="#">
              <div className="row">
                <div className="col-6 col-12-mobile"><input type="text" name="name" placeholder="Nombre" /></div>
                <div className="col-6 col-12-mobile"><input type="text" name="email" placeholder="Correo" /></div>
                <div className="col-12">
                  <textarea name="message" placeholder="Mensaje"></textarea>
                </div>
                <div className="col-12">
                  <input type="submit" value="Enviar" />
                </div>
              </div>
            </form>

          </div>
        </section>

      </div>

      <div id="footer">

        <ul className="copyright">
          <li>&copy; PRODUCCION TECNICA DE COMESTIBLES, Sociedad por Acciones Simplificada de Capital Variable. Todos los derechos reservados.</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
        </ul>

      </div>
    </div>
  );
}

export default App;
